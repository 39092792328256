import { useQuery } from "@tanstack/react-query";
import { Grid, useTheme, Stack, Typography, useMediaQuery, Skeleton } from "@mui/material";
import { LineChart, CurveType } from "@mui/x-charts";
import { getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { DashboardStatisticsResponse } from "../../Models/DashboardStatisticsResponse";
import { formatYearMonth } from "../../../../../Infrastructure/Utils/DateFunctions";
import { CustomBox } from "../../../../../Layout/CustomBox";


export const ResellerStatistic = () => {
  const isDarkMode = useAppSelector(store => store.settings.theme === "dark");

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:400px)");

  const { isLoading, data } = useQuery({
    queryKey: ["dashboard-reseller"],
    queryFn: async () => await fetchMyDepragApi<DashboardStatisticsResponse[]>(
      null,
      "dashboard/statistics",
      "GET",
      dispatch,
      store.getState() as RootState),
    cacheTime: 0,
  });

  const mappingSeries = (): { dataKey: string, label: string, color: string, curve: CurveType }[] => {
    const lines = [
      { key: "UserCount", color: "#32992c" },
      { key: "GoldLicencesCount", color: "#FCC45F" },
      { key: "NotUsedLicenses", color: "#a37512" }];

    const allLines = { curve: "linear" as CurveType };

    return lines.map(line => ({
      ...allLines,
      dataKey: line.key,
      label: getTranslation(line.key),
      color: line.color,
    }));
  };

  const mappingForDataset = (data: DashboardStatisticsResponse[]) => {
    return data.map(dat => ({
      ...dat,
      NotUsedLicenses: Math.max(0, dat.GoldLicencesCount - dat.AssignedGoldLicencesCount),
    }));
  };

  if (isLoading || (data && data.length <= 0)) return (<Skeleton variant="rectangular" height={355} sx={{ padding: isMobile ? "24px 12px" : "24px", }} />);

  return (
    <CustomBox elevation={0}
      sx={{
        backgroundColor: isDarkMode ? "#ffffff0f" : "#0000000f",
        border: `1px solid ${theme.palette.cardBorder.main}`,
        padding: isMobile ? "24px 12px" : "24px",
      }}>
      <Stack direction={"row"} spacing={1} useFlexGap flexWrap={"wrap"}>
        {mappingSeries().map((key, index) => (
          <Stack key={index} direction={"row"} spacing={0.5} alignItems={"center"}>
            <span style={{ width: 20, height: 20, backgroundColor: key.color, borderRadius: "50%" }} />
            <Typography component={"span"}>{key.label}</Typography>
          </Stack>
        ))}
      </Stack>

      <Grid item xs={12}>
        <LineChart height={400}
          xAxis={[
            {
              dataKey: "Month",
              valueFormatter: (value) => formatYearMonth(value),
              tickNumber: data?.length,
              max: data ? data.at(25)?.Month : 25,
              scaleType: "band",
            },
          ]}
          series={mappingSeries()}
          dataset={data ? mappingForDataset(data) : []}
          margin={{ top: 24, right: 24, bottom: 24, left: 44 }}
          slotProps={{ legend: { hidden: true } }}
        />
      </Grid>
    </CustomBox>
  );
};

