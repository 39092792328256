import {
    Tooltip,
    IconButton,
    Grid,
    Divider,
    Box,
    Skeleton,
    Button,
    FormControlLabel,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { GridColDef, GridRenderCellParams, GridRowParams, GridSelectionModel } from '@mui/x-data-grid';
import { ArrowForward } from "@mui/icons-material";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { CopyChip } from "../../../../../Layout/CopyChip";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { EquipmentByCompanyResultDto } from "../Model/EquipmentByCompanyResultDto";
import { EquipmentByCompanyItem } from "../Model/EquipmentByCompanyItem";
import { setSelectedEquipmentIds } from "../Assign/Store/EquipmentByCompanyAssignSlice";

export const EquipmentByCompanyTable = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(store => store.settings.language);
    const navigate = useNavigate();
    const location = useLocation();

    const [searchValue, setSearchValue] = useState<string>("");
    const [showLicensed, setShowLicensed] = useState(false);

    const [rowSelectionModel, setRowSelectionModel] = useState<GridSelectionModel>([]);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["equipmentByCompany"],
        queryFn: async () => await fetchMyDepragApi<EquipmentByCompanyResultDto>(
             {PreferredIsoLanguageCode: language},
            "installedbase/equipment/byCompany",
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    useEffect(() => {
        refetch();
    }, [location, refetch]);

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const convertToDateString = (date: Date | string) => {
        return new Date(date).toLocaleDateString(language, {month: '2-digit', day: '2-digit', year: 'numeric'})
    }

    const openDetails = (row: EquipmentByCompanyItem) => {
        dispatch(setSelectedEquipmentIds({
            equipmentIds: [row.EquipmentId],
            assignedToCustomerId: row.AssignedToCustomerId,
            resetManyAssigned: false
        }));
        navigate("/installedbase/byCompany/assign");
    }

    const showAssignedSwitch = data && data.HasAssignedEquipments;
    const rowsSelected = rowSelectionModel.length > 0

    const columns: GridColDef[] = [
        {
            field: 'SerialNumber',
            headerName: getTranslation("EquipmentByCompanySerialNumber"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, EquipmentByCompanyItem, any>) => {
                return <CopyChip showIconAfterCopy label={params.row.SerialNumber} isCopyEnabled  />
            }
        },
        {
            field: 'SapProductNumber',
            headerName: getTranslation("EquipmentByCompanySapProductNumber"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'ProductDescription',
            headerName: getTranslation("EquipmentByCompanyProductDescription"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'WarrantyEndDate',
            headerName: getTranslation("EquipmentByCompanyWarrantyEndDate"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridRenderCellParams<any, EquipmentByCompanyItem, any>) => convertToDateString(params.row.WarrantyEndDate),
        },
        {
            field: 'AssignedToCustomerName',
            headerName: getTranslation("AssignedToCustomerName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'AssignedToCustomerInternalId',
            headerName: getTranslation("AssignedToCustomerInternalID"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 100,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, EquipmentByCompanyItem, any>) => (
                <strong>
                    <Tooltip title={getTranslation("AssignEquipment")}>
                        <IconButton color={'darky'} onClick={() => openDetails(params.row)}>
                            <ArrowForward />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
    ];

    const rows = data
      ? data.Items.filter(x =>
        x.IsAssigned === showLicensed &&
        (x.SerialNumber?.toString().includes(searchValue.toLocaleLowerCase()) ||
          (x.SapProductNumber?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
          (convertToDateString(x.WarrantyEndDate).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
          (x.AssignedToCustomerInternalId?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))), )
      : [];


    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("EquipmentByCompanyInfoText") }}
                                    />

                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>

                                <Grid item xs={12} sm={6} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                  <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>

                                {showAssignedSwitch &&
                                  <Grid item xs={12} sm={6} md={rowsSelected ? 3 : 6} my={"auto"}
                                        sx={{textAlign: {xs: "left", sm: "right"}, ml: {xs:2, sm:0}}}>
                                      <FormControlLabel control={<CustomSwitch/>} checked={showLicensed}
                                                        onChange={(e, value) => setShowLicensed(value)}
                                                        label={getTranslatedText("ShowAssigned")}
                                                        sx={{textAlign: "left"}}/>
                                  </Grid>
                                }

                                {rowsSelected && <Grid item xs={12} sm={6} md={showAssignedSwitch ? 3 : 6}
                                                       my={"auto"}
                                                       sx={{
                                                           textAlign: {
                                                               xs: "left",
                                                               sm: showAssignedSwitch ? "left" : "right",
                                                               md: "right",
                                                           },
                                                       }}>
                                    <Button startIcon={<ArrowForward/>} sx={{ml: 1}} variant="contained" color="success"
                                            onClick={() => {
                                                dispatch(setSelectedEquipmentIds({equipmentIds: rowSelectionModel as number[], resetManyAssigned: showLicensed ? true : false}));
                                        navigate("/installedbase/byCompany/assign");
                                    }}>{getTranslatedText("AssignAmountEquipment", [rowSelectionModel.length.toString()])}</Button>
                                </Grid>}

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={language === "de" ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={rows}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={true}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        selectionModel={rowSelectionModel}
                                        onSelectionModelChange={setRowSelectionModel}
                                        getRowId={(x) => x.EquipmentId}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                        onRowDoubleClick={(params) => openDetails(params.row)}
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}