import { Box, Button, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material"
import { Close, FileUpload, InsertDriveFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { LanguageConsumer, getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch } from "../../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../../Infrastructure/Store/store";
import { CustomBox } from "../../../../../../Layout/CustomBox";
import { PrereleaseCreateRequestDto } from "../Models/PrereleaseCreateRequestDto";
import dayjs from 'dayjs';
import { TextFieldFormControl } from "../../../../../../Layout/Inputs/TextFieldFormControl";
import { EmptyPrereleaseCreateForm, PrereleaseCreateForm } from "./Models/PrereleaseCreateForm";
import { usePrereleaseCreateFormValidator } from "./Models/usePrereleaseCreateFormValidator";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ControlledDatePicker } from "../../../../../../Layout/Inputs/ControlledDatePicker";
import { PrereleaseCreateDoneProps } from "./PrereleaseCreateDone";
import { PrereleaseCreateResultDto } from "../Models/PrereleaseCreateResultDto";
import { DefaultResponse } from "../../../../../../Infrastructure/Api/Model/DefaultResponse";
import { AzureBlobStorageFileService } from "../../../../../../Infrastructure/FileService/Azure/AzureBlobStorageFileService";
import { LinearProgressWithLabel } from "../../../../../../Layout/Loading/LinearProgressWithLabel";

const maxFileSizeInBytes = 10000000000

const formatBytes = (valueInBytes) => {
    if (valueInBytes === 0) return "0 Bytes";

    const units = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const factor = 1000;
    let unitIndex = 0;

    while (valueInBytes >= factor && unitIndex < units.length - 1) {
        valueInBytes /= factor;
        unitIndex++;
    }

    return `${Math.round(valueInBytes)} ${units[unitIndex]}`;
}


interface PrereleaseCreateContentProps {
    onPrereleaseCreateDone: (props: PrereleaseCreateDoneProps) => void
}

export const PrereleaseCreateContent = ({ onPrereleaseCreateDone }: PrereleaseCreateContentProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { PrereleaseCreateFormValidator } = usePrereleaseCreateFormValidator();
    const [isFileToWebsiteLoading, setIsFileToWebsiteLoading] = useState<boolean>();
    const fileContent = useRef<ArrayBuffer | undefined>();
    const [fileName, setFileName] = useState<string | undefined>();
    const [fileSizeInBytes, setFileSizeInBytes] = useState<number | undefined>();
    const [actualLoadedBytesToBlob, setActualLoadedBytesToBlob] = useState<number | undefined>(1);

    const {
        control, register, handleSubmit, formState: { errors, }
    } = useForm<PrereleaseCreateForm>({
        defaultValues: EmptyPrereleaseCreateForm,
        values: EmptyPrereleaseCreateForm,
        resolver: zodResolver(PrereleaseCreateFormValidator),
    });

    const submitPrereleaseToBackend = async (formData: PrereleaseCreateForm) => {
        if (fileContent.current) {
            setIsSubmitting(true);
            try {
                const request = {
                    Description: formData.description,
                    DownloadValidUntil: formData.validUntil?.toDate(),
                    FileName: fileName,
                } as PrereleaseCreateRequestDto

                const result = await fetchMyDepragApi<DefaultResponse>(request, `software/prerelease/create`, 'POST', dispatch, store.getState() as RootState, undefined, true);

                const payload = result.Payload as PrereleaseCreateResultDto;
                const blobFileService = new AzureBlobStorageFileService(payload.StorageAccountSasToken);

                const uploadResponse = await blobFileService.UploadFileToBlobContainer(payload.BlobContainerName,
                    fileContent.current as ArrayBuffer,
                    fileName as string,
                    (props) => { setActualLoadedBytesToBlob(props.loadedBytes) });

                setIsSubmitting(false);

                if (uploadResponse && uploadResponse._response.status === 201) {
                    toast.success(getTranslation("SuccessfullyUploadedFile"));
                    onPrereleaseCreateDone({
                        DownloadUrl: payload.DownloadUrl,
                        DownloadValidUntil: payload.DownloadValidUntil
                    } as PrereleaseCreateDoneProps)
                }
                else{
                    toast.error(getTranslation("FailedToUploadFileToBlobTryAgain"));
                }

            } catch (error) {
                setIsSubmitting(false);
                toast.error(error as string);
            }
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.size > maxFileSizeInBytes) {
                toast.error(getTranslation("MaxFileSizePrereleaseExceeded", [formatBytes(maxFileSizeInBytes.toString())]))
                return;
            }

            setFileName(file.name);
            setFileSizeInBytes(file.size);
            setIsFileToWebsiteLoading(true);
            const reader = new FileReader();

            reader.onload = () => {
                fileContent.current = reader.result as ArrayBuffer;
                setIsFileToWebsiteLoading(false);
                event.target.value = '';
            };

            reader.onerror = (ja) => {
                toast.error(getTranslation("FailedToReadFile"));
                fileContent.current = undefined;
                setFileName(undefined);
                setFileSizeInBytes(undefined);
                setIsFileToWebsiteLoading(false);
                event.target.value = '';
            };

            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBox elevation={0} sx={{
                        minHeight: 200,
                        height: '100%',
                        backgroundColor: 'transparent',
                        border: `1px solid ${theme.palette.cardBorder.main}`,
                        backgroundSize: 'cover',
                        position: 'relative',
                    }}>

                        <form onSubmit={handleSubmit(submitPrereleaseToBackend)}>
                            <Grid container direction={'row'} spacing={2}>

                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                    <Typography variant="h6" fontWeight={600}>
                                        {getTranslatedText("PrereleaseCreateTitle")}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldFormControl
                                        hasEndAdornment={false}
                                        labelText={getTranslatedText('PrereleaseDescrition')}
                                        disabled={isSubmitting}
                                        propertyName='description'
                                        register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />


                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledDatePicker
                                        name="validUntil"
                                        control={control}
                                        label={getTranslatedText("ValidUntil")}
                                        minDate={dayjs()}
                                        disabled={isSubmitting}
                                        maxDate={dayjs().add(30, 'day')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="outlined"
                                        component="label"
                                        fullWidth
                                        color="darky"
                                        disabled={fileName !== undefined}
                                        loading={isFileToWebsiteLoading}
                                    >
                                        {getTranslatedText("SelectFilePrerelease")}

                                        <input type="file" hidden onChange={handleFileChange} />

                                    </LoadingButton>
                                    {fileName && fileContent.current &&
                                        <Grid mt={2} item xs={12}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Box sx={{ display: "flex", gap: 1 }}>
                                                    <InsertDriveFile />
                                                    <Typography>{fileName}</Typography>
                                                </Box>
                                                <Tooltip title={getTranslatedText("Delete")}>
                                                    <IconButton
                                                    disabled={isSubmitting}
                                                        onClick={() => {
                                                            fileContent.current = undefined;
                                                            setFileName(undefined);
                                                        }}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>

                                <Grid item xs={12}>
                                    <LoadingButton
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={!fileName}
                                        startIcon={<FileUpload />}
                                        fullWidth
                                        color="success"
                                        variant="contained">
                                        {getTranslatedText("UploadPrerelease")}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                        {isSubmitting && actualLoadedBytesToBlob && fileSizeInBytes &&
                            <Grid mt={2} item xs={12}>
                                <LinearProgressWithLabel value={(actualLoadedBytesToBlob / fileSizeInBytes) * 100} />
                            </Grid>
                        }

                    </CustomBox>
                </>
            }
        </LanguageConsumer>
    )
}
