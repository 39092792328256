import { useCallback, useEffect, useRef, useState } from "react";
import { closeVideoAndImageRecorder, toggleVideoAndImageRecorder } from "../Store/VideoAndImageRecorderSlice";
import Webcam from "react-webcam";
import { Box, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { FiCircle, FiStopCircle } from "react-icons/fi";
import { AddCircle, ReplayRounded } from "@mui/icons-material";
import toast from "react-hot-toast";
import { useAppDispatch } from "../../../../../../../../Infrastructure/Store/hooks";
import { base64ToFile } from "../../../../../../../../Infrastructure/Utils/Base64ToFile";
import { blobToBase64Async } from "../../../../../../../../Infrastructure/Utils/BlobToBase64";
import { LanguageConsumer } from "../../../../../../../../Infrastructure/Internationalisation/TranslationService";
import { isSafari, isMobileSafari } from "react-device-detect";

const videoConstraints = {
    facingMode: "enviroment",
};

interface ImageRecorderProps {
    addFileToList: (fileList: File) => void;
}

export const VideoRecorderView = (props: ImageRecorderProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [videoSource, setVideoSource] = useState<string>('');
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [capturingDone, setCapturingDone] = useState<boolean>(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [fileName, setFileName] = useState<string>("");

    const videoFormat = isSafari || isMobileSafari ? "mp4" : "webm";

    const addVideoToList = async () => {
        if (videoSource && videoSource.length > 0) {
            let newDate = new Date();
            let name = fileName.trim().length > 0 ? fileName : `Video_${(newDate.toJSON().slice(0, 10))}-${newDate.getHours()}_${newDate.getMinutes()}_${newDate.getSeconds()}`;
            var file = await base64ToFile(`data:video/${videoFormat};base64,${videoSource}`, `${name}.${videoFormat}`, false, true);
            props.addFileToList(file);
            handleClose();
        }
    }

    const handleClose = () => {
        setVideoSource("");
        setFileName("");
        setLoading(true);
        setCapturingDone(false);
        setRecordedChunks([])
        dispatch(toggleVideoAndImageRecorder())
    }

    useEffect(() => {
        if (recordedChunks.length > 0 && capturingDone && !capturing && videoSource.length === 0) {
            handleDownload();
        }
    }, [recordedChunks, capturingDone, capturing, videoSource])

    const handleDownload = useCallback(async () => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: `video/${videoFormat}`
            });

            const base64Video = await blobToBase64Async(blob);
            var newDate = new Date();
            setFileName(`Video_${(newDate.toJSON().slice(0, 10))}-${newDate.getHours()}_${newDate.getMinutes()}_${newDate.getSeconds()}`)
            setVideoSource(base64Video);
            setRecordedChunks([]);
        }
    }, [recordedChunks]);

    const handleStartCaptureClick = useCallback(() => {
        if (webcamRef && webcamRef.current && (webcamRef.current as any).stream) {
            setCapturing(true);
            setVideoSource("");

            mediaRecorderRef.current = new MediaRecorder((webcamRef.current as any).stream, {
                mimeType: `video/${videoFormat}`
            }) as any;

            (mediaRecorderRef.current as any).addEventListener(
                "dataavailable",
                handleDataAvailable
            );

            (mediaRecorderRef.current as any).addEventListener(
                "stop",
                handleDownload
            );

            (mediaRecorderRef.current as any).start();
        }

    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(async () => {
        (mediaRecorderRef.current as any).stop();
        setCapturingDone(true);
        setCapturing(false);

    }, [mediaRecorderRef, webcamRef, setCapturing]);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    {!capturingDone ? <>
                        <Webcam className="my-webcam" onUserMediaError={() => {
                            setVideoSource("");
                            setLoading(true);
                            setCapturingDone(false);
                            setRecordedChunks([]);
                            dispatch(closeVideoAndImageRecorder())
                            toast.error(getTranslatedText("NoCameraFoundOrNoPermissions"), { id: "NoCameraFoundOrNoPermissions" });
                        }} ref={webcamRef} videoConstraints={videoConstraints} style={{
                            position: "absolute",
                            textAlign: "center",
                            zIndex: 8,
                            right: 0,
                            height: "calc(100vh - 260px)",
                            width: "100%",
                            objectFit: "contain",
                        }} />

                        <IconButton onClick={capturing ? handleStopCaptureClick : handleStartCaptureClick} size="large" sx={{ zIndex: 999999999, position: 'absolute', bottom: 30, left: '50%', transform: 'translate(-50%, 0)', backgroundColor: 'rgba(0,0,0,0.3)' }}>
                            {capturing ? <FiStopCircle color="#f03e3e" size={60} /> : <FiCircle color="#f03e3e" size={60} />}
                        </IconButton>
                    </>
                        :
                        <>
                            <Box component={'div'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 100, textAlign: 'left', pt: 1, pl: 3 }}>
                                <TextField sx={{ width: '90%' }} InputProps={{ disableUnderline: true, sx: { fontSize: 32, p: 1 } }} size="medium" variant="standard" value={fileName} onChange={(e) => setFileName(e.target.value)}></TextField>
                            </Box>

                            {videoSource.trim().length > 0 && <video id='myvideo' src={`data:video/${videoFormat};base64,${videoSource}`} style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', maxHeight: '100%', width: isMobile ? '100%' : '50%', height: 'auto',  paddingTop: "80px" }} controls>
                            </video>}

                            <Box component={'div'} sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: 150, pointerEvents: "none", textAlign: 'center', pt: 6 }}>
                                <Tooltip PopperProps={{ sx: { zIndex: 9999999 } }} title={getTranslatedText("RecordNewVideo")}>
                                    <IconButton size="large" sx={{ background: theme.palette.footer.light, pointerEvents: "auto" }} onClick={() => {
                                        setVideoSource("");
                                        setRecordedChunks([]);
                                        setCapturingDone(false)
                                    }}>
                                        <ReplayRounded sx={{ fontSize: 40, color: '#444' }} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip PopperProps={{ sx: { zIndex: 9999999 } }} title={getTranslatedText("AddVideo")}>
                                    <IconButton size="large" sx={{ ml: 2, background: theme.palette.footer.light, pointerEvents: "auto" }} onClick={addVideoToList}>
                                        <AddCircle color="success" sx={{ fontSize: 40 }} />
                                    </IconButton>
                                </Tooltip>

                            </Box>

                        </>
                    }
                </>

            }
        </LanguageConsumer>
    )
}