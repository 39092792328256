import { Box, Chip, Divider, FormControlLabel, Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toggleShowOnlyNotClosedTickets } from "../Store/ServiceRequestsSlice";
import { PiArrowRightLight } from "react-icons/pi";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks";
import { LanguageConsumer, getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { CopyChip } from "../../../../Layout/CopyChip";
import { DataGridSearch } from "../../../../Layout/DataGrid/DataGridSearch";
import { CustomSwitch } from "../../../../Layout/Switch/CustomSwitch";
import { StripedDataGrid } from "../../../../Layout/DataGrid/StripedDataGrid";
import { globalDateFormatOptions } from "../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { RootState, store } from "../../../../Infrastructure/Store/store";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { TicketOverviewItemDto, TicketOverviewResponseDto } from "../Models/TicketOverviewResponseDto";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { getStateColor, getStateTranslationKey } from "./ServiceRequestsDefaults";

export const ServiceRequestsTable = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const [searchValue, setSearchValue] = useState<string>("");
    const showOnlyNotClosedTickets = useAppSelector(store => store.serviceRequests.showOnlyNotClosedTickets);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["tickets"],
        queryFn: async () => await fetchMyDepragApi<TicketOverviewResponseDto>(
            {},
            `tickets`,
            'GET',
            dispatch,
            store.getState() as RootState)
    });

    const showOnlyNotClosedTicketsChanged = (value: boolean) => {
        dispatch(toggleShowOnlyNotClosedTickets());
    }

    useEffect(() => {
        refetch();
    }, [location, refetch]);

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const openDetails = (params: GridRowParams) => {
        navigate(`/serviceRequests/details/${params.row.Id}`)
    }

    const setTicketStateValues = () => {
        if (data) {
            return [
                ...new Set(data.Items.map((x) => getStateTranslationKey(x.State))),
            ];
        }
        return [];
    };

    const getTicketReactionRequiredValue = (ticket: TicketOverviewItemDto) : string => {
        return getTranslation(getTicketReactionRequired(ticket)? "NoReactionRequired" : "ReactionRequired");
    }

    const getTicketReactionRequiredColor = (ticket: TicketOverviewItemDto) => {
        return getTicketReactionRequired(ticket)? "default" : "darky";
    }

    const getTicketReactionRequired = (ticket: TicketOverviewItemDto) => ticket.State === 4 || (ticket.LastContactCustomer && ticket.LastContactDeprag && ticket.LastContactCustomer < ticket.LastContactDeprag);

    const columns: GridColDef[] = [
        {
            field: 'Number',
            headerName: getTranslation("ServiceTicketNumber"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => {
                return <CopyChip showIconAfterCopy label={params.row.Number} isCopyEnabled />
            }
        },
        {
            field: 'Title',
            headerName: getTranslation("ServiceTicketTitle"),
            flex: 1,
            minWidth: 230,
        },
        {
            field: 'CustomerName',
            headerName: getTranslation("ServiceTicketCustomerName"),
            flex: 1,
            minWidth: 230,
        },
        {
            field: 'Owner',
            headerName: getTranslation("ServiceTicketOwner"),
            flex: 1,
            minWidth: 230,
        },
        {
            field: 'StateLanguageKey',
            headerName: getTranslation("ServiceTicketState"),
            flex: 1,
            minWidth: 80,
            type: 'singleSelect',
            valueOptions: setTicketStateValues(),
            renderCell: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => {
                return <Chip color={getStateColor(params.row.State)} label={getStateTranslationKey(params.row.State)} />
            },
            valueGetter: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => getStateTranslationKey(params.row.State)
        },
        {
            field: 'ReactionRequired',
            headerName: getTranslation("ServiceTicketReactionRequired"),
            flex: 1,
            minWidth: 130,
            renderCell: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => {
                return <Chip color={getTicketReactionRequiredColor(params.row)} label={getTicketReactionRequiredValue(params.row)} />
            },
            valueGetter: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => getTicketReactionRequiredValue(params.row)
        },
        {
            field: 'IsGoldTicket',
            headerName: getTranslation("IsGoldTicket"),
            flex: 1,
            minWidth: 130,
            renderCell: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => {
                return params.row.IsGoldTicket? <Chip sx={{background: (theme) => theme.palette.buttonBackgroundGold.main,
                    color: "black",
                    fontWeight: "bold",}}label={getTranslation("GoldTicket")} /> : <Chip label={getTranslation("NonGoldTicket")}/>
            },
            valueGetter: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => getTranslation(params.row.IsGoldTicket? "GoldTicket" : "NonGoldTicket")
        },
        {
            field: 'LastContactCustomer',
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            headerName: getTranslation("ServiceTicketLastContactCustomer"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => {
                return <Chip color="default" variant="outlined" label={params.row.LastContactCustomer? new Date(params.row.LastContactCustomer).toLocaleTimeString(language, globalDateFormatOptions) : "-"} />
            }
        },
        {
            field: 'LastContactDeprag',
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            headerName: getTranslation("ServiceTicketLastContactDeprag"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => {
                return <Chip color="default" variant="outlined" label={params.row.LastContactDeprag? new Date(params.row.LastContactDeprag).toLocaleTimeString(language, globalDateFormatOptions): "-"} />
            }
        },
        {
            field: 'CreatedAt',
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            headerName: getTranslation("ServiceTicketCreatedAt"),
            flex: 1,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => {
                return <Chip color="default" variant="outlined" label={new Date(params.row.CreatedAt).toLocaleDateString(language, globalDateFormatOptions)} />
            }
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 50,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, TicketOverviewItemDto, any>) => (
                <strong>
                    <Tooltip title={getTranslation("CommonDetails")}>
                        <IconButton color={'darky'} component={NavLink} to={`/serviceRequests/details/${params.row.Id}`}>
                            <PiArrowRightLight />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },

    ];

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            {renderServiceTicketsGrid(getTranslatedText)}
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )


    function renderServiceTicketsGrid(getTranslatedText: (key: string, values?: Array<string>) => string) {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ textAlign: 'left' }}>
                        <div
                            dangerouslySetInnerHTML={{ __html: getTranslatedText("ServiceRequestsInfoText") }}
                        />
                        <Divider sx={{ my: 3, mb: 2 }} />
                    </Grid>

                    <Grid item xs={12} md={5} my={'auto'} sx={{ overflowX: 'hidden', textAlign: 'left' }}>
                        <DataGridSearch searchValue={searchValue} search={search} />
                    </Grid>

                    <Grid item xs={12} md={7} my={'auto'} sx={{ textAlign: 'right' }}>
                        <FormControlLabel control={<CustomSwitch />} checked={!showOnlyNotClosedTickets} onChange={(e, value) => showOnlyNotClosedTicketsChanged(value)} label={getTranslatedText("OnlyShowNotClosedTickets")} />
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                    <Box component={'div'} sx={{ width: '100%' }}>
                        <StripedDataGrid
                            localeText={language === "de" ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                            rows={data ? data.Items.filter(x =>
                                (x.Title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    x.Title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    x.CustomerName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    x.Owner.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    getTranslatedText(x.StateLanguageKey).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    getTranslatedText(x.IsGoldTicket? "GoldTicket" : "NonGoldTicket").toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    new Date(x.CreatedAt).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase()) ||
                                    getTicketReactionRequiredValue(x).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    (x.LastContactCustomer && new Date(x.LastContactCustomer).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase())) ||
                                    (x.LastContactDeprag && new Date(x.LastContactDeprag).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase())) ||
                                    x.Number.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) && (!showOnlyNotClosedTickets || x.State !== 4)
                            ) : []}
                            autoHeight={true}
                            columns={columns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            checkboxSelection={false}
                            disableColumnFilter={false}
                            disableColumnMenu={false}
                            disableSelectionOnClick={true}
                            disableColumnSelector
                            disableVirtualization={false}
                            hideFooter={false}
                            getRowId={(x) => x.Id}
                            onRowDoubleClick={openDetails}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            components={{
                                LoadingOverlay: () => <Skeleton variant="rectangular" height={70} sx={{ mb: 4, mx: 1 }} />,
                            }}
                            loading={isLoading}
                        />
                    </Box>
                </Grid>
            </div>
        )
    }
}
